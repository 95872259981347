import { useState, useEffect, useRef } from 'react';




const switchThemeMode = (setThemeMode) => {
  if (JSON.parse(localStorage.getItem("robinosThemeMode")) === 'dark') {
    localStorage.setItem("robinosThemeMode", JSON.stringify('light'));
    setThemeMode('light')
    document.documentElement.classList.remove('dark')
    return
  } else {
    localStorage.setItem("robinosThemeMode", JSON.stringify('dark'));
    setThemeMode('dark')
    document.documentElement.classList.add('dark')
    return
  }
}



const setModeThemeOnStartUp = () => {
  if (JSON.parse(localStorage.getItem("robinosThemeMode")) === 'dark' || (!('robinosThemeMode' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    document.documentElement.classList.add('dark')
    return
  }
  
  document.documentElement.classList.remove('dark')
  return
}





const Head = ({ connectToWallet, hashedWallet }) => {

  const [themeMode, setThemeMode] = useState(JSON.parse(localStorage.getItem("robinosThemeMode")) || 'light'),
        [openMenu, setOpenMenu] = useState(false),
        [subMenu, setSubMenu] = useState(''),
        scrollToEventTrigger = useRef('events')

  
  useEffect(() => {
    setModeThemeOnStartUp();
    if (window.location.hash === '#events') {
      console.log(window.location.hash);
      scrollToEventTrigger.current.click()
    }
  }, [])
  



  return (
    <div className={` w-full px-5 bg-transparent pt-5 pb-3 sm:flex flex-row justify-between items-center`}>
      <div className="">
        <a href="/" className="">
          <img src={(themeMode === 'dark') ? "/img/logo/robinos-white-text.png" : "/img/logo/robinos-black-text.png"} alt="Robinos Logo" className="h-20 sm:h-24 py-2 -mt-5 -mb-1 sm:-my-10" />
        </a>
      </div>


      <div className={`bg-gray-100 dark:bg-gray-900 sm:bg-transparent dark:sm:bg-transparent transition-all duration-700 ease-in-out
      flex flex-col sm:flex-row sm:items-center sm:static sm:h-fit sm:p-0 sm:w-fit
      fixed h-screen top-0 left-0 w-2/5 z-20 ${openMenu === false ? '-translate-x-full sm:translate-x-0' : 'translate-x-0'}`}>
        <div className="flex flex-row justify-end items-center sm:hidden my-4 px-5">
          <span className="cursor-pointer text-2xl text-red-600" onClick={() => {
            setSubMenu('')
            setOpenMenu(false)
          }}>
            <i className="fas fa-close"></i>
          </span>
        </div>
        
        <section className="flex-grow divide-y-2 divide-gray-300 sm:divide-y-0 sm:mr-8 sm:flex sm:gap-x-8 font-semibold text-sm uppercase">
          <div className="border-t-2 border-gray-300 sm:border-none">
            <a href="https://robinos.finance/#events" className="block px-3 py-3.5 sm:p-0" ref={scrollToEventTrigger}>Tokenized Prediction</a>
          </div>
          <div className="">
            <a href="https://robinos.finance/rewards" className="block px-3 py-3.5 sm:p-0">Rewards</a>
          </div>

          <div className="relative py-3.5 sm:p-0">
            <p className="flex justify-between items-center space-x-1 px-3 sm:p-0 cursor-pointer" onClick={() => {
              subMenu !== 'versus' ? setSubMenu('versus') : setSubMenu('')
             }}>
              <span className="block">Versus</span>
              <i className="fas fa-caret-down"></i>
            </p>
            <div className={`sm:-right-12 sm:left-auto sm:top-6 w-48 h-fit absolute top-0 left-full bg-gray-200 dark:bg-gray-800 transition-all duration-200 ease-in-out divide-y divide-white ${subMenu !== 'versus' ? '-z-10 opacity-0 -translate-x-full sm:-translate-y-full scale-0 sm:overflow-hidden' : 'translate-x-0 sm:translate-y-0 scale-100 z-10 sm:overflow-auto'}`}>
              <div class="py-2 pl-4">
                <a href="https://robinos.finance/versus/stelos" className="block py-1">sTlos - Telos</a>
              </div>
            </div>
          </div>

          <div className="">
            <a href="https://migration.robinos.finance" className="block px-3 py-3.5 sm:p-0">Migration</a>
          </div>
        </section>

        <section className="py-10 px-1 sm:p-1 sm:hidden">
          <span className="font-medium"> switch to </span>
          <div className="dark:bg-gray-500 bg-gray-300 rounded-md py-2 px-1.5 cursor-pointer flex items-center justify-between" onClick={() => { switchThemeMode(setThemeMode) }}>
            <span className="text-sm sm:hidden"> {(themeMode === 'dark') ? 'light' : 'dark'} theme </span>
            <i className={`${(themeMode === 'dark') ? 'fas fa-sun' : 'fas fa-moon'} text-lg`}></i>
          </div>
        </section>
      </div>

      <div className="flex flex-row justify-between items-center gap-x-4">
        <section className="hidden sm:flex py-10 sm:p-0 items-center justify-center sm:flex-none">
          <div className="dark:bg-gray-500 bg-gray-300 rounded-md py-2 px-3 cursor-pointer" onClick={() => { switchThemeMode(setThemeMode) }}>
            <i className={`${(themeMode === 'dark') ? 'fas fa-sun' : 'fas fa-moon'} text-lg`}></i>
          </div>
        </section>


        <button className="border-2 border-gray-900 dark:border-white px-2 py-1 sm:px-3 sm:py-1.5 rounded-md" onClick={connectToWallet}>
          <span className="flex items-center space-x-2">
            <span>{hashedWallet}</span>
            <i className="fas fa-wallet"></i>
          </span>
        </button>


        <span className="cursor-pointer text-2xl block sm:hidden" onClick={() => { setOpenMenu(!openMenu) }}>
          <i className="fas fa-bars"></i>
        </span>
      </div>

      <section className={`bg-black bg-opacity-30 h-full w-full ${openMenu === true ? 'fixed top-0 left-0 z-10' : 'hidden'}`} onClick={ () => {
        setSubMenu('')
        setOpenMenu(false)
      }}>
      </section>
    </div>
  )

}

export default Head
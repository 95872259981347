import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './index.css'
import { Route, Routes, BrowserRouter } from 'react-router-dom';


import VersusSTelosTokenHomeNew from "./pages/VersusSTelosTokenHomeNew";


import Head from './components/Head';
import Help from "./components/Help";
import ChainChangedPopUp from './components/ChainChangedPopUp';
import PageLoading from './components/PageLoading';


import { checkIfMetamaskIsInstalled, connectToMetamask, getConnectedWalletAddress, checkWalletChainAndSwitch, getConnectedWalletBalance } from './appCore/globalFunctions';







function App() {

  // const chainInfo = (window.location.pathname === '/farm/stake' || window.location.pathname === '/farm/liquidity') ? { hex: '0x89', title: 'Polygon', symbol: 'MATIC' } : { hex: '0x28', title: 'Telos', symbol: 'TLOS' },
  const chainInfo = { hex: '0x28', title: 'Telos', symbol: 'TLOS' },
        [isLoading, setIsLoading] = useState(true),
        [showChainPopUp, setShowChainPopUp] = useState(false),
        // METAMASK RELATED STATES
        [hashedWallet, setHashedWallet] = useState('Connect your wallet'),
        [connectedWallet, setConnectedWallet] = useState(''),
        [openInstallLinks, setOpenInstallLinks] = useState(false),
        [openMetamaskWarning, setOpenMetamaskWarning] = useState(false),
        [walletIsConnected, setWalletIsConnected] = useState(false),
        [walletBalance, setWalletBalance] = useState({
          networkTokenBalance: 0,
          standardTokenBalance: 0,
          standardTokenSymbol: 'RBN'
        }),
        [switchedNetwork, setSwitchedNetwork] = useState({
          status: false,
          portfolioMessage: `Please connect to the ${chainInfo.title} mainnet to see event's in your portfolio`,
          eventMessage: `Please connect to the ${chainInfo.title} mainnet to see event's date`,
          teamMessage: `Connect to ${chainInfo.title} mainnet to see sale info on team`,
        })



  
  
  useEffect(() => {
    setTimeout(() => {
      checkIfMetamaskIsInstalled().then(installed => {
        setIsLoading(false)

        if (installed === true) {
          window.ethereum.on('chainChanged', (_chainId) => {
            if (typeof _chainId !== undefined) {
              if (_chainId !== chainInfo.hex) { /* METAMASK IS NOT ON THE PREFERRED NETWORK */
                setShowChainPopUp(true)
                setSwitchedNetwork(previousState => ({...previousState, status: false}))
                return true;
              }
            }
            setShowChainPopUp(false)
            setSwitchedNetwork(previousState => ({...previousState, status: true}))
            return false;
          });

          
          return getConnectedWalletAddress().then(account => {
            if (typeof account !== 'boolean') {
              setConnectedWallet(account)
              setHashedWallet(`${account.slice(0, 6)}........${account.slice(account.length - 4, account.length)}`.toLowerCase());
              setWalletIsConnected(true)

              return checkWalletChainAndSwitch(chainInfo.hex).then(connected => {
                console.log('Network Switched', connected)
                if (connected === true) {
                  getConnectedWalletBalance().then(balance => setWalletBalance(balance))
                  setSwitchedNetwork(previousState => ({...previousState, status: true}))
                  return
                }
              })
            }
          })
        }

        setOpenMetamaskWarning(true)
        return;
      })


      setIsLoading(false)
    }, 1200);
  }, [chainInfo.hex, walletIsConnected])










  const connectToWallet = async () => {
    connectToMetamask().then(metamaskData => {
      if (typeof metamaskData === typeof true) {
        setOpenMetamaskWarning(true)
        return;
      }

      return checkWalletChainAndSwitch(chainInfo.hex).then(connected => {
        setHashedWallet(metamaskData.hashedAccount)
        setWalletIsConnected(true)

        console.log('Network Switched', connected)
        if (connected === true) {
          setSwitchedNetwork(previousState => ({...previousState, status: true}))
          return
        }
      })
    });
  }







  return (
    isLoading === true ?
      <PageLoading />
    :
      <div className="min-h-screen relative mb-16">

        <Head
          connectToWallet={connectToWallet}
          hashedWallet={hashedWallet}
          walletIsConnected={walletIsConnected}
          chainInfo={chainInfo}
          walletBalance={walletBalance}
        />


        <Help />


        <BrowserRouter>
          <Routes>
            

            <Route path="/"
              element={<VersusSTelosTokenHomeNew
                switchedNetwork={switchedNetwork}
                walletIsConnected={walletIsConnected}
                connectedWallet={connectedWallet}
              />}
            />
          </Routes>
        </BrowserRouter>
        


        {/* METAMASK NOT INSTALLED WARNING */}
        <section className={`bg-black bg-opacity-30 h-full w-full ${openMetamaskWarning === true ? 'fixed top-0 left-0 z-10' : 'hidden'} flex items-center justify-center px-1 font-medium`}>
          <figure className="bg-white text-gray-800 max-w-sm p-4 rounded text-sm">
            <h5> System checks could not find metamask installed on this browser. </h5>
            <div className="flex justify-between items-center mt-6">
              <div className="relative">
                <span className="cursor-pointer bg-red-700 rounded text-white px-3 py-2" onClick={() => { setOpenInstallLinks(!openInstallLinks) }}>Click to install Metamask</span>

                <figure className={`bg-white shadow-lg text-black w-full absolute top-7 pt-2 flex flex-col divide-y-2 overflow-y-hidden ${openInstallLinks === true ? 'h-36' : 'h-0'}`}>
                  <a href="https://metamask.io/download" target="_blank" rel="noreferrer" className="p-3">For desktop</a>

                  <a href="https://play.google.com/store/apps/details?id=io.metamask&hl=en&gl=US" target="_blank" rel="noreferrer" className="p-3">For android</a>

                  <a href="https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202" target="_blank" rel="noreferrer" className="p-3">For iOS</a>
                </figure>
              </div>

              <label className="border border-red-700 text-red-700 font-semibold px-3 py-2 rounded cursor-pointer" onClick={() => { setOpenMetamaskWarning(false) }}>Close</label>
            </div>
          </figure>
        </section>


        {showChainPopUp === true && <ChainChangedPopUp chainName={chainInfo.title} chainId={chainInfo.hex} />}


        <ToastContainer position="bottom-left" theme="colored" />
      </div>
  );
}

export default App;
import { useState, useEffect } from 'react'
import { calculateTimeLeft } from '../../appCore/miscFunctions'
import VersusTelosInput from './VersusTelosInput'



const VersusUSDCTokenHomeCard = ({ eventObj, switchedNetwork, filterOption, isTokenApproved, setIsTokenApproved }) => {
  const [isReadMore, setIsReadMore] = useState(true),
        [timeLeft, setTimeLeft] = useState({
          days: '00', hours: '00',
          minutes: '00', seconds: '00',
        })


  
  useEffect(() => {
    if (switchedNetwork.status === true) {
      setInterval(() => {
        setTimeLeft(calculateTimeLeft(eventObj?.saleEnd));
      }, 1000)
    }
  }, [eventObj, switchedNetwork.status])
  
  

  
  return (
    <figure className={`sm:col-span-4 col-span-12 bg-gray-300 dark:bg-gray-800 shadow-md shadow-gray-500 dark:shadow-xl dark:shadow-gray-800 w-full rounded-md ${filterOption === '' ? '' : filterOption !== eventObj.category ? 'hidden' : ''}`}>
      <section className={`w-full p-2 font-semibold flex flex-col justify-center relative ${isReadMore ? 'h-24' : ''}`}>
        {isReadMore ?
          `${(eventObj.betQuestion.length > 70 ?
            `${eventObj.eventCode.slice(0, 70)}...`
              :
            `${eventObj.eventCode.slice(0, 70)}`)}`
          :
          <h6 className='' dangerouslySetInnerHTML={{ __html: eventObj.betQuestion }}></h6>
        }

        <h6 className='text-xs text-blue-700' dangerouslySetInnerHTML={{ __html: eventObj.betLink }}></h6>
        
        {eventObj.betQuestion.length > 70 &&
          <div className={`absolute -bottom-2.5 right-2 bg-blue-700 rounded-full h-5 w-5 flex items-center justify-center cursor-pointer`} onClick={() => { setIsReadMore(!isReadMore) }}>
            <i className={`fas ${isReadMore ? 'fa-arrow-down fa-bounce' : 'fa-arrow-up'} text-white text-center`}></i>
          </div>
        }
      </section>

      <section className="grid gap-4 p-4 rounded-b-md bg-gray-400 dark:bg-gray-600">
        <div className="grid grid-cols-2 gap-y-1">

          <figure className="col-span-2 sm:col-span-1 text-center">
            {
              eventObj.saleActive === false ?
                <div className="font-semibold flex items-center justify-center h-full bg-gray-900 text-white rounded-md py-6">
                  Sale has ended
                </div>
                :
                <figure className="grid grid-cols-4 gap-1 text-xs font-medium bg-gray-900 text-white rounded-md px-2 py-1">
                  <div className="col-span-4 text-xs font-bold text-justify">
                    Sale Ends In
                  </div>
                  <div className="col-span-1 text-center">
                    <h6 className="font-semibold text-lg"> {timeLeft.days} </h6>
                    Day{timeLeft.days > 1 && 's'}
                  </div>
                  <div className="col-span-1 text-center">
                    <h6 className="font-semibold text-lg"> {timeLeft.hours} </h6>
                    Hour{timeLeft.hours > 1 && 's'}
                  </div>
                  <div className="col-span-1 text-center">
                    <h6 className="font-semibold text-lg"> {timeLeft.minutes} </h6>
                    Min{timeLeft.minutes > 1 && 's'}
                  </div>
                  <div className="col-span-1 text-center">
                    <h6 className="font-semibold text-lg"> {timeLeft.seconds} </h6>
                    Sec
                  </div>
                </figure>
            }
          </figure>

          <figure className="col-span-2 sm:col-span-1 my-auto font-bold w-full text-white text-center">
            <p className=""> Prize Pool </p>
            {(eventObj.totalStakedTokens).toFixed(2)} <sup className="">STLOS</sup>
          </figure>
        </div>

        <div className="flex items-center font-bold text-sm text-center border-2 border-red-800 rounded-md">
          <h3 className="w-full py-1">
            {eventObj.response1Aggregate > 0 ? eventObj.response1Aggregate : 0}%
          </h3>

          <h3 className="bg-white text-gray-900 rounded-r-md w-full py-1">
            {eventObj.response2Aggregate > 0 ? eventObj.response2Aggregate : 0}%
          </h3>
        </div>

        <div className="grid grid-cols-11 gap-2">
          <div className={`col-span-5 space-y-0.5 text-xs text-center ${(+eventObj.winningIndex === 1 && eventObj.saleActive === false) && 'opacity-30'}`}>
            {eventObj.iconType === 'image'?
              <div className={`h-20 rounded-md bg-no-repeat bg-center bg-contain`} style={{
                backgroundImage: `url(${eventObj.resp1Icon})`
              }}>
              </div>
            :
              <div className="h-20 rounded-md flex items-center justify-center">
                <i className={`${eventObj.resp1Icon} text-6xl text-green-700`}></i>
              </div>
            }

            <h4 className="font-black uppercase"> {eventObj.response1} </h4>
            <h5 className="text-xs font-medium">
              ROI: {(eventObj.totalStakedTokens / eventObj.response1TotalDeposit - eventObj.totalStakedTokens / eventObj.response1TotalDeposit === 0) ? ((0.95 * eventObj.totalStakedTokens) / eventObj.response1TotalDeposit).toFixed(2) : '0.00'}
            </h5>
          </div>

          <div className="col-span-1 flex items-center justify-center font-black"> vs </div>

          <div className={`col-span-5 space-y-0.5 text-xs text-center ${(+eventObj.winningIndex === 0 && eventObj.saleActive === false) && 'opacity-30'}`}>
            {eventObj.iconType === 'image' ?
              <div className={`h-20 rounded-md bg-no-repeat bg-center bg-contain`} style={{
                backgroundImage: `url(${eventObj.resp2Icon})`
              }}>
              </div>
            :
              <div className="h-20 rounded-md flex items-center justify-center">
                <i className={`${eventObj.resp2Icon} text-6xl text-red-700`}></i>
              </div>
            }

            <h4 className="font-black uppercase"> {eventObj.response2} </h4>
            <h5 className="text-xs font-medium">
              ROI: {(eventObj.totalStakedTokens / eventObj.response2TotalDeposit - eventObj.totalStakedTokens / eventObj.response2TotalDeposit === 0) ? ((0.95 * eventObj.totalStakedTokens) / eventObj.response2TotalDeposit).toFixed(2) : '0.00'}
            </h5>
          </div>


          {eventObj.saleActive === true ?
            <>
              <VersusTelosInput
                tokenSaleOngoing={eventObj.saleActive}
                supportedSide={0}
                eventObj={eventObj}
                setIsTokenApproved={setIsTokenApproved}
                isTokenApproved={isTokenApproved}
                switchedNetwork={switchedNetwork}
                userStake={eventObj.userDepositOnTeam1}
              />

              <div className="col-span-1 flex items-center justify-center font-black"></div>

              <VersusTelosInput
                tokenSaleOngoing={eventObj.saleActive}
                supportedSide={1}
                eventObj={eventObj}
                setIsTokenApproved={setIsTokenApproved}
                isTokenApproved={isTokenApproved}
                switchedNetwork={switchedNetwork}
                userStake={eventObj.userDepositOnTeam2}
              />
            </>
          :
            <div className="col-span-11 text-sm text-center">
              {eventObj.eventCancelled === true ?
                "The event was cancelled and no winner was declared. All stakes have been refunded"
              :
                <h6 className="">
                  {+eventObj.winningIndex === 0 ?
                    `Team A won! Rewards distributed`
                    // `${eventObj.response1} is declared the winner of this event`
                  :
                    `Team B won! Rewards distributed`
                    // `${eventObj.response2} is declared the winner of this event`
                  }
                </h6>
              }
            </div>
          }
        </div>
      </section>
    </figure>
  )
}

export default VersusUSDCTokenHomeCard
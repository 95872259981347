import { useEffect, useState } from 'react'
import VersusTelosTokenHomeCard from '../components/VersusComponents/VersusTelosTokenHomeCard'
import SideBetFilter from '../components/SideBetFilter'
import { sTelosSideBetEvents } from '../variables/sTelosSideBetEvents'
import { getSideBetEventData, getTokenSaleStatus, getUserStakeInEvent, isTokenApproved } from '../appCore/versusFunctions/VersusWeb3ReadFunctions'





const VersusSTelosTokenHomeNew = ({ connectedWallet, switchedNetwork }) => {

  const [filterOption, setFilterOption] = useState(''),
        [tokenApproved, setIsTokenApproved] = useState(false),
        [activeTab, setActiveTab] = useState('ongoing'),
        [sideBetEvents, setSideBetEvents] = useState([]);




  useEffect(() => {
    let eventsArray = []
    if (switchedNetwork.status === true && sTelosSideBetEvents !== undefined) {
      isTokenApproved().then(isApproved => setIsTokenApproved(isApproved))

      sTelosSideBetEvents.forEach(sTelosSideBetEvent => {
        getUserStakeInEvent(sTelosSideBetEvent.eventCode).then(userStake => {
          sTelosSideBetEvent.userStaked = Number(userStake[0]) + Number(userStake[1]) !== 0 ? true : false
          sTelosSideBetEvent.userDepositOnTeam1 = userStake[0] / Math.pow(10, 18)
          sTelosSideBetEvent.userDepositOnTeam2 = userStake[1] / Math.pow(10, 18)
        })

        // CHECK SALE STATUS OF THE EVENT
        getTokenSaleStatus(sTelosSideBetEvent?.eventCode).then(saleInfo => {
          sTelosSideBetEvent.saleActive = saleInfo?.saleActive
          sTelosSideBetEvent.saleEnd = saleInfo?.saleEnd
        })

        // GET INFORMATION ABOUT THE EVENT
        getSideBetEventData(sTelosSideBetEvent?.eventCode).then(eventData => {
          // if (eventData.length) {
          if (typeof eventData === 'object') {
            sTelosSideBetEvent.standardTokenAddress = eventData?.standardTokenAddress
            sTelosSideBetEvent.totalTokensDeposited = eventData?.totalTokensDeposited
            sTelosSideBetEvent.winningIndex = eventData?.winningIndex
            sTelosSideBetEvent.eventCancelled = eventData?.cancelled
            sTelosSideBetEvent.rewardDistributed = eventData?.rewardDistributed
            sTelosSideBetEvent.totalStakedTokens = (+eventData?.totalTokensDeposited[0] + +eventData?.totalTokensDeposited[1]) / Math.pow(10, 18)

            sTelosSideBetEvent.response1TotalDeposit = (eventData?.totalTokensDeposited[0] / Math.pow(10, 18))
            sTelosSideBetEvent.response2TotalDeposit = (eventData?.totalTokensDeposited[1] / Math.pow(10, 18))
            sTelosSideBetEvent.response1Aggregate = Math.round(eventData?.totalTokensDeposited[0] * 100 / (parseInt(eventData?.totalTokensDeposited[0]) + parseInt(eventData?.totalTokensDeposited[1])))
            sTelosSideBetEvent.response2Aggregate = Math.round(eventData?.totalTokensDeposited[1] * 100 / (parseInt(eventData?.totalTokensDeposited[0]) + parseInt(eventData?.totalTokensDeposited[1])))
          }
        })

        if (eventsArray.includes(sTelosSideBetEvent) === false) {
          eventsArray.push(sTelosSideBetEvent)
        }
      });

      // const sortedEventsArray = [...eventsArray].sort((a, b) => parseInt(a.saleEnd) - parseInt(b.saleEnd))
      // console.log(eventsArray);
      setSideBetEvents(eventsArray)
    }
  }, [switchedNetwork.status, connectedWallet, tokenApproved])
  




  return (
      <section className="relative">
        <div className="flex justify-center items-center my-6 sm:mt-6 sm:mb-0">
          <div className={`font-semibold px-0.5 h-9 flex items-center rounded-l-md bg-gradient-to-br from-yellow-400 via-red-600 to-red-700`} onClick={() => {
            setActiveTab('ongoing')
          }}>
            <label className={`px-3.5 py-1.5 sm:py-1 rounded-l-md cursor-pointer text-sm sm:text-base ${activeTab === 'ongoing' ? 'bg-gray-50 text-gray-800' : 'text-white'}`}>
              Active Events
            </label>
          </div>

          <div className={`font-semibold px-0.5 h-9 flex items-center bg-gradient-to-br from-yellow-400 via-red-600 to-red-700`} onClick={() => {
            setActiveTab('past')
          }}>
            <label className={`px-3.5 py-1.5 sm:py-1 cursor-pointer text-sm sm:text-base ${activeTab === 'past' ? 'bg-gray-50 text-gray-800' : 'text-white'}`}>
              Event Results
            </label>
          </div>

          <div className={`font-semibold px-0.5 h-9 flex items-center rounded-r-md bg-gradient-to-br from-yellow-400 via-red-600 to-red-700`} onClick={() => {
            setActiveTab('staked')
          }}>
            <label className={`px-3.5 py-1.5 sm:py-1 rounded-r-md cursor-pointer text-sm sm:text-base ${activeTab === 'staked' ? 'bg-gray-50 text-gray-800' : 'text-white'}`}>
              Staked Events
            </label>
          </div>
        </div>

        <div className="px-4 sm:px-16 pb-6">
          <SideBetFilter
            setFilterOption={setFilterOption}
            filterPlace='TelosHome'
          />
        </div>




        <section className={`grid grid-cols-12 gap-y-8 sm:gap-x-10 px-4 sm:px-16 py-6 ${activeTab === 'ongoing' ? '' : 'hidden'}`}>
          {sideBetEvents?.filter(sideBetEvent => sideBetEvent.saleActive === true).length ?
            sideBetEvents.map((eventObj, index) => (
              eventObj.saleActive === true &&
              <VersusTelosTokenHomeCard
                key={index}
                eventObj={eventObj}
                setIsTokenApproved={setIsTokenApproved}
                isTokenApproved={tokenApproved}
                switchedNetwork={switchedNetwork}
                connectedWallet={connectedWallet}
                onTab={'active'}
                filterOption={filterOption.toLowerCase()}
              />
            ))
          :
            // USER HAS NO EVENT IN THEIR PORTFOLIO
            <div className="col-span-12 text-center sm:text-lg font-semibold">
              Events with ongoing sale will appear here when they are available
            </div>
          }
        </section>

        <section className={`grid grid-cols-12 gap-y-8 sm:gap-x-10 px-4 sm:px-16 py-6 ${activeTab === 'past' ? '' : 'hidden'}`}>
          {sideBetEvents?.filter(sideBetEvent => sideBetEvent.saleActive === false).length > 1 ?
            sideBetEvents.map((eventObj, index) => (
              eventObj.saleActive === false &&
              <VersusTelosTokenHomeCard
                key={index}
                eventObj={eventObj}
                setIsTokenApproved={setIsTokenApproved}
                isTokenApproved={tokenApproved}
                switchedNetwork={switchedNetwork}
                connectedWallet={connectedWallet}
                onTab={'ended'}
                filterOption={filterOption.toLowerCase()}
              />
            ))
          :
            // USER HAS NO EVENT IN THEIR PORTFOLIO
            <div className="col-span-12 text-center sm:text-lg font-semibold">
              Events will appear here at the end of their sale period
            </div>
          }
        </section>

        <section className={`grid grid-cols-12 gap-y-8 sm:gap-x-10 px-4 sm:px-16 py-6 ${activeTab === 'staked' ? '' : 'hidden'}`}>
          {sideBetEvents?.filter(sideBetEvent => sideBetEvent.userStaked === true).length > 1 ?
            sideBetEvents.map((eventObj, index) => (
              eventObj.userStaked === true &&
                <VersusTelosTokenHomeCard
                  key={index}
                  eventObj={eventObj}
                  setIsTokenApproved={setIsTokenApproved}
                  isTokenApproved={tokenApproved}
                  switchedNetwork={switchedNetwork}
                  connectedWallet={connectedWallet}
                  onTab={'staked'}
                  filterOption={filterOption.toLowerCase()}
                />
            ))
          :
            // USER HAS NO EVENT IN THEIR PORTFOLIO
            <div className="col-span-12 text-center sm:text-lg font-semibold">
              Events in your portfolio will appear here when you stake for any team
            </div>
          }
        </section>
      </section>
  )
}

export default VersusSTelosTokenHomeNew
import { useState } from 'react'
import {
  approveSpendForTelosToken,
  depositTokenForTelosEvent
} from '../../appCore/versusFunctions/VersusWeb3WriteFunctions'









const VersusUSDCInput = ({ eventObj, tokenSaleOngoing, supportedSide, userStake, isTokenApproved, setIsTokenApproved }) => {
  const [amountToStake, setAmountToStake] = useState('')





  return (
    <div className="col-span-5 grid gap-1 text-xs text-center rounded-md">
      <input type="number" name='buyAmount' className="outline-none rounded p-1.5 mb-1 text-sm text-black w-full placeholder-gray-500" min={0} placeholder="Amount" value={amountToStake} onChange={(e) => {
        if (e.target.value > -1 || e.target.value === "") {
          setAmountToStake(e.target.value)
        }
      }} />
      {/* <div className="flex items-center bg-white pr-1">
        <input type="number" name='buyAmount' className="outline-none rounded p-1.5 mb-1 text-sm text-black w-full" min={0} placeholder="Amount" value={amountToStake} onChange={(e) => {
          if (e.target.value > -1 || e.target.value === "") {
            setAmountToStake(e.target.value)
          }
        }} />

        <span className="bg-red-700 hover:bg-gradient-to-br from-yellow-400 via-red-600 to-red-700 text-white rounded text-xxs font-medium cursor-pointer px-0.5">max</span>
      </div>  */}
      
      {
        isTokenApproved === false ?
          <button className={`danger-btn hover-danger text-sm py-1.5 w-full rounded ${tokenSaleOngoing === false && 'bg-opacity-50 pointer-events-none'}`} onClick={(e) => {
            e.preventDefault();
            approveSpendForTelosToken().then(response => {
              if (response === true) {
                setIsTokenApproved(response)
              }
            })
          }}> Approve </button>
        :
          <button className={`danger-btn hover-danger text-sm py-1.5 w-full rounded ${tokenSaleOngoing === false && 'bg-opacity-50 pointer-events-none'}`} onClick={(e) => {
            e.preventDefault();
            depositTokenForTelosEvent(supportedSide, eventObj.eventCode, amountToStake.toString()).then(response => {
              if (response === true) {
                setAmountToStake('')
              }
            })
          }}> Purchase </button>
      }
      Your Stake: {userStake}
      {/* Your Stake: {userStake.toFixed(2)} */}
    </div>
  )
}

export default VersusUSDCInput
import { toast } from 'react-toastify';
import {
  SIDEBET_CONTRACT_ABI,
  SIDEBET_CONTRACT_ADDRESS,
  STANDARD_TOKEN_ABI,
  STANDARD_TOKEN_ADDRESS
} from '../coreVariables';
import { getConnectedWalletAddress, initContract, web3 } from '../globalFunctions';








export const approveSpendForTelosToken = async () => {
  const connectedWallet = await getConnectedWalletAddress(),
        teamTokenContract = await initContract(STANDARD_TOKEN_ABI, STANDARD_TOKEN_ADDRESS),
        approveAmount = "99999999999000000000000000000"; //(2^256 - 1 );
        // approveAmount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"; //(2^256 - 1 );


  return new Promise((resolve, reject) => {
    teamTokenContract.methods.approve(SIDEBET_CONTRACT_ADDRESS, approveAmount)
    .send({
      from: connectedWallet,
      // gas: 21000,
      // gasPrice: null,
    })
    .once("transactionHash", (hash) => {
      console.log("hash", hash);
      toast.info("Your transaction is pending confirmation on the block");
    })
    .on('receipt', (receipt) => {
      toast.success('Approval successfully sent!')
      console.info('Transaction Hash', receipt.transactionHash);
    })
    .once('confirmation', (receipt) => {
      toast.success("Your transaction is confirmed! ");
      console.info(receipt);

      return resolve(true)
    })
    .on('error', (approveSpendForTelosTokenPromiseError) => {
      toast.error(approveSpendForTelosTokenPromiseError.message)
      // console.info('approveSpendForTelosTokenPromiseError', approveSpendForTelosTokenPromiseError)
      return reject(false)
    })
  }).catch((approveSpendForTelosTokenError) => {
    toast.error(approveSpendForTelosTokenError.message)
    console.info('approveSpendForTelosTokenError', approveSpendForTelosTokenError)
    return false;
  });
}




export const depositTokenForTelosEvent = async (supportedSide, eventCode, amountToStake) => {
  const connectedWallet = await getConnectedWalletAddress(),
        stakeContract = await initContract(SIDEBET_CONTRACT_ABI, SIDEBET_CONTRACT_ADDRESS),
        amount = web3.utils.toWei(amountToStake);


  console.info('Supported Side:', supportedSide);
  console.info('Amount to deposit:', amount);



  return new Promise((resolve, reject) => {
    stakeContract.methods.deposit(eventCode, supportedSide, amount)
    .send({
      from: connectedWallet,
      // gas: 500000,
      gas: 500000,
      // gasPrice: null,
    })
    .once("transactionHash", (hash) => {
      console.log("hash", hash);
      toast.info("Your transaction is pending confirmation on the block");
    })
    .once('receipt', (receipt) => {
      console.info('receipt', receipt);
      toast.success("Your transaction receipt is ready! ");
    })
    .once('confirmation', (confirmation) => {
      console.info('confirmation', confirmation);
      toast.success("Your transaction is confirmed! ");
      return resolve(true);
    })
    .on('error', (err) => {
      console.info('depositTokenForTelosEventPromiseError', err);
      toast.error(err.message)
      return reject(false)
    })
  }).catch(depositTokenForTelosEventCatchError => {
    console.info('depositTokenForTelosEventCatchError', depositTokenForTelosEventCatchError);
    toast.error(depositTokenForTelosEventCatchError.message)
    return false
  });
}




export const withdrawTokenForTelosEvent = async (supportedSide, eventCode) => {
  const connectedWallet = await getConnectedWalletAddress(),
        stakeContract = await initContract(SIDEBET_CONTRACT_ABI, SIDEBET_CONTRACT_ADDRESS)
      

  console.info('Supported Side:', supportedSide);



  return new Promise((resolve, reject) => {
    stakeContract.methods.withdraw(eventCode)
    .send({
      from: connectedWallet,
      gas: 500000,
      // gasPrice: null,
    })
    .once("transactionHash", (hash) => {
      console.log("hash", hash);
      toast.info("Your transaction is pending confirmation on the block");
    })
    .once('receipt', (receipt) => {
      console.info('receipt', receipt);
      toast.success("Your transaction receipt is ready! ");
    })
    .once('confirmation', (confirmation) => {
      console.info('confirmation', confirmation);
      toast.success("Your transaction is confirmed! ");
      return resolve(true);
    })
    .on('error', (err) => {
      console.info('withdrawTokenForTelosEventPromiseError', err);
      toast.error(err.message)
      return reject(false)
    })
  }).catch(withdrawTokenForTelosEventCatchError => {
    console.info('withdrawTokenForTelosEventCatchError', withdrawTokenForTelosEventCatchError);
    toast.error(withdrawTokenForTelosEventCatchError.message)
    return false
  });
}
// resp1Icon: 'fas fa-check',
// resp2Icon: 'fas fa-xmark',

// resp1Icon: 'fas fa-arrow-up',
// resp2Icon: 'fas fa-arrow-down',

// <h6 class='text-xs mt-2'> </h6> <h6 class='text-xs mt-1'> </h6>


export const sTelosSideBetEvents = [
  // { eventCode: 'Can we ever find a reliable person to work with?',
  //   betQuestion: "Can we ever find a reliable person to work with?",
  //   betLink: "<a href='' target='_blank'></a>",
  //   category: 'EPL',
  //   iconType: 'icon',
  //   response1: {
  //     team: 'Keep Looking!',
  //     img: 'fas fa-check',
  //     loser: false
  //   },
  //   response2: {
  //     team: 'Hell no...',
  //     img: 'fas fa-xmark',
  //     loser: false
  //   },
  // },

  { eventCode: 'EPL 22/23 - 4/3/23 - Man City v. Newcastle',
    betQuestion: "EPL 22/23 - 4/3/23 - Man City v. Newcastle",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Man City -1.5',
    loser1Class: true,
    resp1Icon: '/img/events/footballImages/teams/manchesterCity.png',
    response2: 'Newcastle +1.5',
    loser2Class: true,
    resp2Icon: 'img/events/footballImages/teams/newcastleUnited.png',
  },
  { eventCode: 'EPL 22/23 - 4/3/23 - Arsenal v. Bournemouth',
    betQuestion: "EPL 22/23 - 4/3/23 - Arsenal v. Bournemouth",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Arsenal -1.5',
    loser1Class: true,
    resp1Icon: '/img/events/footballImages/teams/arsenal.png',
    response2: 'Bournemouth +1.5',
    loser2Class: true,
    resp2Icon: 'img/events/footballImages/teams/afcBournemouth.png',
  },
  { eventCode: 'EPL 22/23 - 4/3/23 - Chelsea v. Leeds',
    betQuestion: "EPL 22/23 - 4/3/23 - Chelsea v. Leeds",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Chelsea -0.5',
    loser1Class: true,
    resp1Icon: '/img/events/footballImages/teams/chelsea.png',
    response2: 'Leeds +0.5',
    loser2Class: true,
    resp2Icon: 'img/events/footballImages/teams/leeds.png',
  },
  { eventCode: 'EPL 22/23 - 4/3/23 - Wolves v. Spurs',
    betQuestion: "EPL 22/23 - 4/3/23 - Wolves v. Spurs",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Wolves +0.5',
    loser1Class: true,
    resp1Icon: '/img/events/footballImages/teams/wolverhamptonWanderers.png',
    response2: 'Spurs -0.5',
    loser2Class: true,
    resp2Icon: 'img/events/footballImages/teams/tottenham.png',
  },
  { eventCode: 'EPL 22/23 - 5/3/23 - Liverpool v. Man Utd',
    betQuestion: "EPL 22/23 - 5/3/23 - Liverpool v. Man Utd",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Liverpool +0.5',
    loser1Class: true,
    resp1Icon: '/img/events/footballImages/teams/liverpool.png',
    response2: 'Man Utd -0.5',
    loser2Class: true,
    resp2Icon: 'img/events/footballImages/teams/manchsterUtd.png',
  },
  { eventCode: 'UFC 285 - 5/3/23 - Jones v. Gane',
    betQuestion: "UFC 285 - 5/3/23 - Jones v. Gane",
    betLink: "<a href='' target='_blank'></a>",
    category: 'UFC',
    iconType: 'image',
    response1: 'Jones',
    loser1Class: true,
    resp1Icon: 'https://dmxg5wxfqgb4u.cloudfront.net/2020-09/JONES_JON_12-29.png?VersionId=2bfn8e7DiK08FbawKKmTPVrJiIOsjp0G',
    response2: 'Gane',
    loser2Class: true,
    resp2Icon: 'https://www.pngfind.com/pngs/m/19-191070_game-of-thrones-reportedly-headhunted-ufc-fighter-conor.png',
  },
  { eventCode: 'EPL 22/23 - 25/2/23 - Leicester v. Arsenal',
    betQuestion: "EPL 22/23 - 25/2/23 - Leicester v. Arsenal",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Leicester +0.5',
    loser1Class: true,
    resp1Icon: '/img/events/footballImages/teams/leicesterCity.png',
    response2: 'Arsenal -0.5',
    loser2Class: true,
    resp2Icon: 'img/events/footballImages/teams/arsenal.png',
  },
  { eventCode: 'EPL 22/23 - 25/2/23 - Bournemouth v. Man City',
    betQuestion: "EPL 22/23 - 25/2/23 - Bournemouth v. Man City",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Bournemouth +2.5',
    loser1Class: true,
    resp1Icon: '/img/events/footballImages/teams/afcBournemouth.png',
    response2: 'Man City - 2.5',
    loser2Class: true,
    resp2Icon: 'img/events/footballImages/teams/manchesterCity.png',
  },
  { eventCode: 'EPL 22/23 - 25/2/23 - Crystal Palace v. Liverpool',
    betQuestion: "EPL 22/23 - 25/2/23 - Crystal Palace v. Liverpool",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Crystal Palace +0.5',
    loser1Class: true,
    resp1Icon: '/img/events/footballImages/teams/crystalPalace.png',
    response2: 'Liverpool - 0.5',
    loser2Class: true,
    resp2Icon: 'img/events/footballImages/teams/liverpool.png',
  },
  { eventCode: 'EPL 22/23 - Tottenham v. Chelsea',
    betQuestion: "EPL 22/23 - Tottenham v. Chelsea",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Tottenham +0.5',
    loser1Class: true,
    resp1Icon: '/img/events/footballImages/teams/tottenham.png',
    response2: 'Chelsea - 0.5',
    loser2Class: true,
    resp2Icon: 'img/events/footballImages/teams/chelsea.png',
  },
  { eventCode: 'EFL Cup Winner - Newcastle v. Man Utd',
    betQuestion: "EFL Cup Winner - Newcastle v. Man Utd",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Newcastle',
    loser1Class: true,
    resp1Icon: '/img/events/footballImages/teams/newcastleUnited.png',
    response2: 'Man Utd',
    loser2Class: true,
    resp2Icon: '/img/events/footballImages/teams/manchesterUtd.png',
  },
  { eventCode: 'Jake Paul v. Tommy Fury',
    betQuestion: "Jake Paul v. Tommy Fury <h6 class='mt-2 text-xs'> In the event of a draw, all staked tokens will be refunded </h6>",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Jake Paul',
    loser1Class: true,
    resp1Icon: 'https://e0.365dm.com/21/11/2048x1152/skysports-jake-paul-boxing_5569121.jpg',
    response2: 'Tommy Fury',
    loser2Class: true,
    resp2Icon: 'https://e0.365dm.com/21/03/1600x900/skysports-tommy-fury-box_5305989.jpg?20210315140610',
  },
  { eventCode: 'Will Light V3 happen by 31 March 2023 (23:59 UTC)?',
    betQuestion: "Will Light V3 happen by 31 March 2023 (23:59 UTC)? <h6 class='mt-2 text-xs'> A token migration or any action leading to V3 from users or the Light team </h6>",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'icon',
    response1: 'Yes',
    loser1Class: true,
    resp1Icon: 'fas fa-check',
    response2: 'No',
    loser2Class: true,
    resp2Icon: 'fas fa-xmark',
  },
  { eventCode: 'EPL 22/23 - 18/2/23 - Aston Villa FC v. Arsenal FC -',
    betQuestion: "EPL 22/23 - 18/2/23 - Aston Villa FC v. Arsenal FC -",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Aston Villa +0.5',
    loser1Class: true,
    resp1Icon: '/img/events/footballImages/teams/aston_villa.png',
    response2: 'Arsenal -0.5',
    loser2Class: true,
    resp2Icon: 'img/events/footballImages/teams/arsenal.png',
  },
  { eventCode: 'EPL 22/23 - 18/2/23 - Nottm Forest FC v. Man City -',
    betQuestion: "EPL 22/23 - 18/2/23 - Nottm Forest FC v. Man City -",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Nottm Forest +1.5',
    loser1Class: true,
    resp1Icon: '/img/events/footballImages/teams/nottinghamForest.jpg',
    response2: 'Man City -1.5',
    loser2Class: true,
    resp2Icon: '/img/events/footballImages/teams/manchesterCity.png',
  },
  { eventCode: 'EPL 22/23 - 18/2/23 - Chelsea FC v. Southampton FC -',
    betQuestion: "EPL 22/23 - 18/2/23 - Chelsea FC v. Southampton FC -",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Chelsea -1.5',
    loser1Class: true,
    resp1Icon: '/img/events/footballImages/teams/chelsea.png',
    response2: 'Southampton +1.5',
    loser2Class: true,
    resp2Icon: '/img/events/footballImages/teams/southampton.png',
  },
  { eventCode: 'EPL 22/23 - 18/2/23 - Newcastle Utd v. Liverpool FC -',
    betQuestion: "EPL 22/23 - 18/2/23 - Newcastle Utd v. Liverpool FC -",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Newcastle -0.5',
    loser1Class: true,
    resp1Icon: '/img/events/footballImages/teams/newcastleUnited.png',
    response2: 'Liverpool +0.5',
    loser2Class: true,
    resp2Icon: '/img/events/footballImages/teams/liverpool.png',
  },
  { eventCode: 'EPL 22/23 - 19/2/23 - Man Utd v. Leicester City -',
    betQuestion: "EPL 22/23 - 19/2/23 - Man Utd v. Leicester City -",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Man Utd -1.5',
    loser1Class: true,
    resp1Icon: '/img/events/footballImages/teams/manchesterUtd.png',
    response2: 'Leicester +1.5',
    loser2Class: true,
    resp2Icon: '/img/events/footballImages/teams/leicesterCity.png',
  },
  { eventCode: '$TLOS Price Prediction - 20/2/23 - Will $TLOS be over $0.20 at 23:59 (UTC) on 28 February 2023? -',
    betQuestion: "$TLOS Price Prediction - 20/2/23 - Will $TLOS be over $0.20 at 23:59 (UTC) on 28 February 2023? -",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'icon',
    response1: 'Over',
    loser1Class: true,
    resp1Icon: 'fas fa-arrow-up',
    response2: 'Under',
    loser2Class: true,
    resp2Icon: 'fas fa-arrow-down',
  },
  { eventCode: 'Border Gavaskar Trophy - 17/2/23 - Who will take the BGT - Australia v. India',
    betQuestion: "Border Gavaskar Trophy - 17/2/23 - Who will take the BGT - Australia v. India",
    betLink: "<a href='' target='_blank'></a>",
    category: 'EPL',
    iconType: 'image',
    response1: 'Australia',
    loser1Class: true,
    resp1Icon: '/img/events/countries/australia.png',
    response2: 'India',
    loser2Class: true,
    resp2Icon: '/img/events/countries/india.png',
  },
];
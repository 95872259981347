import {
  SIDEBET_CONTRACT_ABI,
  SIDEBET_CONTRACT_ADDRESS,
  STANDARD_TOKEN_ADDRESS,
  STANDARD_TOKEN_ABI
} from "../coreVariables";
import { getConnectedWalletAddress, initContract } from '../globalFunctions';










export const isTokenApproved = async () => {
  try {
    const account = await getConnectedWalletAddress(),
          teamTokenContract = await initContract(STANDARD_TOKEN_ABI, STANDARD_TOKEN_ADDRESS),
          isApproved = await teamTokenContract.methods.allowance(account, SIDEBET_CONTRACT_ADDRESS).call();

    // console.log(isApproved);
    return (parseInt(isApproved) === 0) ? false : true;
  } catch (isTokenApprovedError) {
    console.info('isTokenApprovedError: ', isTokenApprovedError.message);
  }
}





export const getTokenSaleStatus = async (eventCode) => {
  try {
    const mainContract = await initContract(SIDEBET_CONTRACT_ABI, SIDEBET_CONTRACT_ADDRESS),
          eventSale = await mainContract.methods.isSaleOn(eventCode).call();

    // console.info(eventCode, eventSale);
    // console.info(mainContract.methods);
    return eventSale;
  } catch (TokenSaleStatusError) {
    console.info('TokenSaleStatusError: ', TokenSaleStatusError.message);
  }
}





export const getSideBetEventReward = async (eventCode) => {
  try {
    const eventContract = await initContract(SIDEBET_CONTRACT_ABI, SIDEBET_CONTRACT_ADDRESS),
          rewardInfo = await eventContract.methods.calculateTotalRewardAndOwnerCut(eventCode).call();

    // console.log(rewardInfo);
    return rewardInfo;
  } catch (getSideBetEventRewardError) {
    console.log(`getSideBetEventRewardError`, getSideBetEventRewardError.message);
    return false
  }
}





export const getUserStakeInEvent = async (eventCode) => {
  try {
    const account = await getConnectedWalletAddress(),
          eventContract = await initContract(SIDEBET_CONTRACT_ABI, SIDEBET_CONTRACT_ADDRESS),
          userStake = await eventContract.methods.getUserTokensDeposited(eventCode, account).call();

    // console.log(userStake);
    return userStake;
  } catch (getUserStakeInEventError) {
    console.log(`getUserStakeInEventError`, getUserStakeInEventError.message);
    return false
  }
}





export const getSideBetEventData = async (eventCode) => {
  try {
    const eventContract = await initContract(SIDEBET_CONTRACT_ABI, SIDEBET_CONTRACT_ADDRESS),
          eventInfo = await eventContract.methods.getSideBetData(eventCode).call();
    
    // console.log(eventInfo);
    return eventInfo;
  } catch (getSideBetEventDataError) {
    console.log(`getSideBetEventDataError`, getSideBetEventDataError.message);
    return false
  }
}